import { fetchApiHub } from 'frontastic/lib/fetch-api-hub';

interface CSVLineItem {
    sku: string;
    quantity: number;
    isValid: boolean;
    qtyIsValid: boolean;
    isSelected: boolean;
}

export const checkQuickOrder = async (data: CSVLineItem[]): Promise<CSVLineItem[]> => {
  return await fetchApiHub(`/action/quickorder/checkCsv`, { method: 'POST' }, {'linesItem':  data});
};
