import { Category } from '@Types/product/Category';
import { Attributes } from '@Types/product/Attributes';

import { revalidateOptions } from 'frontastic';
import { fetchApiHub } from 'frontastic/lib/fetch-api-hub';
import { ProductQueryResponse } from 'frontastic/provider/Frontastic/UseProducts';
import useSWR, { mutate } from 'swr';

export const query = async (search: string): Promise<ProductQueryResponse> => {
  return await fetchApiHub(`/action/product/query?${search}`, { method: 'GET' });
};
export const getAttributeGroup = async (key: string): Promise<string[]> => {
  return await fetchApiHub(`/action/product/getAttributeGroup?key=${key}`, { method: 'GET' });
};

export const getCategories = (): Category[] => {
  const { data } = useSWR('/action/product/queryCategories', fetchApiHub, revalidateOptions);

  if (data?.items) {
    return data.items;
  }
  return [];
};

export const getAttributes = (): Attributes[] => {
  const { data } = useSWR('/action/product/attributes', fetchApiHub, revalidateOptions);
  
  const attributes: Attributes[] = [];
  if (data?.items) {
    data?.items.forEach((productType) => {
      productType.attributes.forEach((attribute) => {
        attributes.push({key: attribute.name, label: attribute.label, type: attribute.type})
      })
    });
  }

  return attributes;
}
